<template>
  <h2 class="mb-3 text-lg font-medium md:text-xl">
    <a :href="`/makers/${maker?.slug}`">{{ makerName }}</a>
  </h2>
  <div class="col-span-6 mb-3 w-full border-b border-gray-700 md:col-span-7 md:border-b-2" />
  <lokal-loading
    v-if="isLoading"
    :loading="isLoading"
  />
  <div
    v-else
    class="mb-5 text-sm text-gray-700 md:grid md:grid-cols-8 md:gap-4 md:place-items-center md:text-base"
  >
    <template v-if="!isMobileOrTablet">
      <p class="text-center">
        Imagen referencial
      </p>
      <p class="text-center md:col-span-2">
        Nombre producto
      </p>
      <p class="text-center">
        Precio público (c/IVA)
      </p>
      <p class="text-center">
        Precio mayorista neto
      </p>
      <p class="text-center">
        Cantidad
      </p>
      <p class="text-center">
        Suma
      </p>
      <p class="text-center">
        Eliminar
      </p>
      <div class="col-span-6 w-full border-b border-gray-700 md:col-span-8" />
    </template>
    <template
      v-for="item in items"
      :key="item.product.id"
    >
      <order-products-by-maker-card
        :item="item"
        :order-in-progress="orderInProgress"
      />
    </template>
    <Field
      v-if="maker?.id && validMakerDestination !== undefined"
      v-model="validMakerDestination"
      hidden
      :name="`${maker.name}-validMakerDestination`"
      :rules="{ isValidDestination: maker.name }"
    />
    <form-input
      v-if="maker?.id"
      v-model="totalProductsPricesValue"
      class="col-span-6 mb-2 md:col-span-7"
      type="number"
      :name="maker.name"
      :show-errors="false"
      hidden
      :rules="{
        required: true,
        minValueMaker: [maker?.minimumPurchaseAmountCents, useFormatCurrency(maker?.minimumPurchaseAmountCents)]
      }"
    />
    <p
      v-if="showMinimum"
      class="col-span-3 mr-auto font-medium text-red-500 md:col-span-3"
    >
      {{ errorMessage }}
    </p>
    <order-products-by-maker-discount-code
      v-else-if="!makerQueryIsLoading"
      :maker="maker"
    />
    <div
      class="col-span-2 ml-auto md:col-span-5"
    >
      <div
        v-if="showRecomendationMessages"
        class="flex flex-col p-2 mb-2 text-sm text-amber-900 bg-yellow-50 rounded-md md:p-3 md:text-base"
      >
        <div
          v-if="showMissingAmountForSubsidizedShipping"
          class="flex items-center w-full"
        >
          <InformationCircleIcon class="shrink-0 mr-2 w-5 h-5" />
          <p>
            Agrega {{ useFormatCurrency(missingAmountForSubsidizedShipping) }} y obten
            {{ useFormatCurrency(subsidizedShippingAmount(nextSubsidizedShipping)) }}
            de descuento en el despacho.
          </p>
        </div>
        <div
          v-if="showMissingAmountForDiscountedShipping"
          class="flex items-center w-full"
        >
          <InformationCircleIcon class="shrink-0 mr-2 w-5 h-5" />
          <p>
            Agrega {{ useFormatCurrency(missingAmountForDiscountedShipping) }} para acceder a 50% de
            descuento en este despacho.
          </p>
        </div>
        <div
          v-if="!hasFreeShipping && nextFreeShipping?.id"
          class="flex items-center w-full"
        >
          <InformationCircleIcon class="shrink-0 mr-2 w-5 h-5" />
          <p>
            Agrega {{ useFormatCurrency(missingAmountForFreeShipping) }} para acceder a despacho gratis.
          </p>
        </div>
        <div
          v-if="nextVolumeDiscount?.id"
          class="flex items-center w-full"
        >
          <InformationCircleIcon class="shrink-0 mr-2 w-5 h-5" />
          <p>
            Agrega {{ useFormatCurrency(missingAmountForVoumeDiscount) }} para acceder a un
            {{ nextVolumeDiscount.discount }}% de descuento.
          </p>
        </div>
      </div>
      <p
        v-if="totalVolumeDiscount > 0"
        class="flex space-x-3 md:justify-end"
      >
        <span>DESCT X VOLUMEN ({{ currentVolumeDiscount.discount }}%):</span>
        <span>{{ useFormatCurrency(totalVolumeDiscount) }}</span>
      </p>
      <p
        v-if="discountCode?.active"
        class="flex space-x-3 md:justify-end"
      >
        <span>CÓDIGO DESCT ({{ discountCode.totalDiscount }}%):</span>
        <span>{{ useFormatCurrency(codeDiscountAmount) }}</span>
      </p>
      <p class="flex space-x-3 md:justify-end">
        <lokal-tooltip
          :position-options="isMobileOrTablet ? 'left' : 'right'"
          information-classes="w-64 md:w-80"
          button-classes="flex items-center space-x-1 text-sm uppercase md:text-base"
          main-label="Sub Total:"
          :information="'Corresponde al monto total de la marca sin IVA.'"
        />
        <span
          v-if="totalVolumeDiscount > 0"
          :class="{ 'line-through': totalVolumeDiscount > 0 }"
        >
          {{ useFormatCurrency(totalProductsPricesValue) }}
        </span>
        <span>{{ useFormatCurrency(productWholesalePriceWithDiscountCode) }}</span>
      </p>
      <template v-if="!showMinimum">
        <div
          v-if="hasFreeShipping || shippingCost === 0"
          class="flex w-full md:justify-end"
        >
          <p class="p-1 mb-1 text-sm text-white bg-lk-light-purple rounded-md">
            Despacho gratis
          </p>
        </div>
        <div
          v-else-if="shippingCost !== cartStore.originalShippingCostPerMaker[makerId]"
          class="flex w-full md:justify-end"
        >
          <p class="flex items-center p-1 mb-1 text-sm text-white bg-lk-light-purple rounded-md">
            <span>¡Despacho con descuento!</span>
          </p>
        </div>
      </template>
      <maker-shipping-price
        :id="`maker-shipping-price-${makerId}`"
        :key="`maker-shipping-price-${makerId}`"
        class="md:justify-end"
        :in-checkout="false"
        :maker-id="makerId"
        :destination-address="destinationAddress"
      />
      <button
        v-if="!!maker.shopPickupAddressId && !shopPickupAddressIsLoading"
        class="flex items-center w-full md:justify-end"
        type="button"
        @click="selectShopPickup"
      >
        <div
          class="mr-2 w-4 h-4 rounded-md border border-lk-green"
          :class="{ 'bg-lk-green': shopPickup }"
        />
        <p>
          Retiro en bodega: {{ shopPickupAddress.address }}, {{ shopPickupAddress.communeName }}
        </p>
      </button>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, inject, onMounted, ref } from 'vue';
import { useQuery } from 'vue-query';
import { Field } from 'vee-validate';
import { InformationCircleIcon } from '@heroicons/vue/24/outline';
import makersApi from 'api/makers';
import useSessionStore from 'stores/user-store';
import useCartStore from 'stores/cart';
import useFormatCurrency from './use/format-currency';
import useTotalItemPriceMethods from './use/total-item-price-methods';
import formInput from './shared/form-input.vue';
import OrderProductsByMakerCard from './order-products-by-maker-card.vue';
import useMakerVolumeDiscounts from './use/maker-volume-discounts';
import lokalTooltip from './shared/lokal-tooltip.vue';
import useValidDestinationQuery from './queries/valid-destination-query';
import useFreeShippingsQuery from './queries/free-shippings-query';
import useMakerFreeShippingHelper from './use/maker-free-shipping-helper';
import useSubsidizedShippings from './use/subsidized-shippings';
import useMakerSubsidizedShippingsHelper from './use/maker-subsidized-shippings-helper';
import MakerShippingPrice from './shared/maker-shipping-price.vue';
import useRegionsQuery from './queries/regions-query';
import useCommuneQuery from './queries/commune-query';
import useAddressQuery from './queries/address-query';
import useMakerCodeDiscounts from './use/maker-code-discounts';
import orderProductsByMakerDiscountCode from './order-products-by-maker-discount-code.vue';

const SUBSIDIZED_FACTOR = 2;

const isMobileOrTablet = inject('isMobileOrTablet');

interface Props {
  items: Item[],
  makerName: string,
  orderInProgress?: boolean,
  showMinimum?: boolean,
  errorMessage?: string
}

const props = withDefaults(defineProps<Props>(), {
  showMinimum: false,
  errorMessage: '',
  orderInProgress: false,
});

const shopPickup = ref(false);

const cartStore = useCartStore();
const sessionStore = useSessionStore();
const currentCommuneId = computed(() => sessionStore.currentCommuneId);
const currentRegionId = computed(() => sessionStore.currentRegionId);

const regionsEnabled = computed(() => !!sessionStore.user?.shopId && !sessionStore.currentAddress?.id);
const { regions } = useRegionsQuery(regionsEnabled);
const { commune } = useCommuneQuery(currentCommuneId, computed(() => ''), computed(() => !!currentCommuneId.value));
const regionSubsidizedMinimum = computed(() => {
  if (sessionStore.currentAddress?.id) return sessionStore.currentAddress?.regionSubsidizedMinimum as number;

  return regions.value?.find((region) => region.id === currentRegionId.value)?.subsidizedMinimum as number;
});
const destinationAddress = computed(() => {
  if (sessionStore.currentAddress?.id) return sessionStore.currentAddress;

  return { regionId: currentRegionId.value, communeId: currentCommuneId.value,
    regionSubsidizedMinimum: regionSubsidizedMinimum.value, communeName: commune.value?.name };
});

const { totalProductPriceForItems } = useTotalItemPriceMethods();
const totalProductsPricesValue = computed(() => totalProductPriceForItems(props.items));

const makerId = computed(() => props.items[0]?.product.makerId);
const makerQuery = useQuery(['maker', makerId], () => makersApi.get(makerId.value), {
  enabled: computed(() => !!makerId.value),
});
const makerQueryIsLoading = computed(() => makerQuery.isLoading.value);
const maker = computed(() => makerQuery.data.value?.data.maker as Maker);

const showMinimumAmount = computed(() => {
  if (maker.value?.minimumPurchaseAmountCents) {
    return totalProductsPricesValue.value < maker.value.minimumPurchaseAmountCents;
  }

  return false;
});

const {
  totalVolumeDiscount,
  currentVolumeDiscount,
  volumeDiscountIsLoading,
  nextVolumeDiscount,
} = useMakerVolumeDiscounts(makerId);
const isLoading = computed(() => makerQuery.isLoading.value || volumeDiscountIsLoading.value);
const missingAmountForVoumeDiscount = computed(() => {
  if (nextVolumeDiscount.value?.id) return nextVolumeDiscount.value.minimumAmountCents - totalProductsPricesValue.value;

  return 0;
});

const { validDestination: validMakerDestination } = useValidDestinationQuery(
  makerId, computed(() => 'Maker'), currentCommuneId,
);

const { codeDiscountAmount, productWholesalePriceWithDiscountCode } = useMakerCodeDiscounts(makerId);

const discountCode = computed(() => cartStore.discountCodes[makerId.value]);

const freeShippingsQueryParams = computed(() => 'q[active_eq]=true');
const { orderedFreeShippings } = useFreeShippingsQuery(makerId, freeShippingsQueryParams);
const { hasFreeShipping, nextFreeShipping, missingAmountForFreeShipping } = useMakerFreeShippingHelper(
  orderedFreeShippings, productWholesalePriceWithDiscountCode, currentRegionId, currentCommuneId,
);

const shippingCost = computed(() => {
  if (shopPickup.value) return 0;

  return cartStore.shippingCostPerMaker[makerId.value] || 0;
});
const subsidizeShippingsQueryParams = computed(() => 'q[active_eq]=true');
const { orderedSubsidizedShippings } = useSubsidizedShippings(
  makerId, subsidizeShippingsQueryParams, computed(() => true),
);
const { nextSubsidizedShipping, missingAmountForSubsidizedShipping } = useMakerSubsidizedShippingsHelper(
  orderedSubsidizedShippings, productWholesalePriceWithDiscountCode,
);

const showMissingAmountForSubsidizedShipping = computed(() =>
  missingAmountForSubsidizedShipping.value > 0 && shippingCost.value > 0 &&
  (!nextFreeShipping.value?.id || missingAmountForSubsidizedShipping.value < missingAmountForFreeShipping.value),
);

function subsidizedShippingAmount(subsidizedShipping: SubsidizedShipping | null | undefined) {
  if (!subsidizedShipping) return 0;
  if (subsidizedShipping.kind === 'with_lokal') {
    return subsidizedShipping.subsidizedAmountCents * SUBSIDIZED_FACTOR;
  }

  return subsidizedShipping.subsidizedAmountCents;
}

const missingAmountForDiscountedShipping = computed(() =>
  destinationAddress.value?.regionSubsidizedMinimum - productWholesalePriceWithDiscountCode.value);
const showMissingAmountForDiscountedShipping = computed(() =>
  (cartStore.itemsMakerIds.length > 1 && missingAmountForDiscountedShipping.value > 0 &&
  orderedSubsidizedShippings.value?.length === 0 &&
  (missingAmountForFreeShipping.value === 0 ||
  missingAmountForDiscountedShipping.value < missingAmountForFreeShipping.value)));

const showRecomendationMessages = computed(() => {
  if (!showMinimumAmount.value) {
    return ((!hasFreeShipping.value && nextFreeShipping.value?.id) || nextVolumeDiscount.value?.id ||
    showMissingAmountForDiscountedShipping.value || showMissingAmountForSubsidizedShipping.value);
  }

  return false;
});

function selectShopPickup() {
  shopPickup.value = !shopPickup.value;
  if (shopPickup.value) {
    cartStore.shopPickupAddressIds[makerId.value] = maker.value?.shopPickupAddressId as number;
  } else {
    delete cartStore.shopPickupAddressIds[makerId.value];
  }
}

onMounted(() => {
  if (cartStore.shopPickupAddressIds[makerId.value]) {
    shopPickup.value = !!cartStore.shopPickupAddressIds[makerId.value];
  }
});

const { address: shopPickupAddress, addressIsLoading: shopPickupAddressIsLoading } = useAddressQuery(
  computed(() => maker.value?.shopPickupAddressId as number),
);
</script>
