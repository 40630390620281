<template>
  <div
    class="flex flex-col py-1 px-3 w-full text-sm font-light text-left text-white sm:px-5 md:text-base"
  >
    <button
      class="flex justify-between items-center w-full hover:underline"
      @click="showDetails = !showDetails"
    >
      <p class="mr-2 text-left">
        - Despacho gratis para ordenes sobre {{ useFormatCurrency(freeShipping.minimumAmountCents) }}
      </p>
      <ChevronDownIcon
        class="shrink-0 w-3 h-3 text-white md:w-4 md:h-4"
        :class="{ 'transform duration-150 ease-in rotate-180': showDetails,
                  'transform duration-150 ease-out': !showDetails }"
      />
    </button>
    <template v-if="showDetails">
      <span v-if="regionNames.length > 0">
        - {{ join(regionNames, ', ') }}
      </span>
      <span v-if="communeNames.length > 0">
        - {{ join(communeNames, ', ') }}
      </span>
    </template>
  </div>
</template>
<script setup lang="ts">
import { computed, ref } from 'vue';
import { ChevronDownIcon } from '@heroicons/vue/24/outline';
import { join } from 'lodash';
import useFormatCurrency from './use/format-currency';

interface MakerShowFreeShippingCardProps {
  freeShipping: FreeShipping;
  startOpen?: boolean;
}

const props = withDefaults(defineProps<MakerShowFreeShippingCardProps>(), {
  startOpen: false,
});
const showDetails = ref(props.startOpen);
const regionNames = computed(() => props.freeShipping.regions.map((region) => region.name));
const communeNames = computed(() => props.freeShipping.communes.map((commune) => commune.name));
</script>
