<template>
  <div class="flex relative flex-col items-center w-full text-gray-700">
    <template
      v-for="message, index in messages"
      :key="message"
    >
      <lokal-banner-message-dialog
        v-if="message[1]"
        v-model="showBanners[index]"
        :show-dialog="message[0].includes('dialog')"
        :color-key="(message[0] as ColorKeyType)"
        :message="message[1]"
        title="Información importante"
      />
    </template>
    <product-list-aside-filters
      v-if="menuStore.showProductFilterSidebar"
      :cross-enabled="true"
      :maker-slug="maker.slug"
      :with-maker-filter="false"
      class="overflow-y-auto fixed left-0 z-20 w-3/5 h-screen text-sm text-gray-700 bg-gray-50 border-r border-gray-300 md:w-80 md:h-auto md:text-base"
      :class="{ 'border-r border-gray-300': isMobile }"
    />
    <div class="flex flex-col items-center py-6 w-full md:py-10">
      <div class="flex flex-col px-4 w-full max-w-6xl md:px-10 2xl:px-0">
        <div class="flex items-end mb-3">
          <a
            href="/makers"
            class="hover:underline"
          >
            Marcas
          </a>
          <span class="mx-1">/</span>
          <span class="font-light capitalize">{{ maker.name }}</span>
        </div>
        <div
          :style="{ 'background-image': `url(${coverImageUrl})` }"
          class="flex object-cover flex-col justify-end p-2 mb-2 w-full h-40 bg-center bg-cover rounded-md md:p-4 md:h-60"
        >
          <button
            class="flex items-center self-end p-2 px-4 text-base text-gray-700 hover:text-white bg-white hover:bg-gray-700 rounded-md md:text-lg"
            @click="manageShowSendMessageDialog"
          >
            <span

              class="hidden mr-2 md:inline-block"
            >Enviar mensaje</span>
            <ChatBubbleLeftRightIcon class="w-5 h-5" />
          </button>
        </div>
        <img
          class="object-cover -mt-14 mb-4 ml-5 w-20 h-20 rounded-full sm:ml-10 md:w-28 md:h-28"
          :src="maker.logoImage?.derivatives['jpgMd']?.url || greenKImage"
        >
        <div class="flex flex-col w-full">
          <h2 class="mb-4 font-serif text-2xl font-medium text-lk-green md:text-3xl">
            {{ maker.name }}
          </h2>
          <p class="mb-2 text-base md:text-lg">
            Pedidos desde
            <template v-if="sessionStore.user?.id">
              <span class="font-semibold">{{ useFormatCurrency(maker.minimumPurchaseAmountCents) }}</span>
              <span> + IVA</span>
            </template>
            <span
              v-else
              class="blur"
            >
              fake value
            </span>
          </p>
          <p class="flex flex-col mb-2 text-sm md:flex-row md:items-center md:space-x-1 md:text-base">
            <lokal-loading
              v-if="addressesAreLoading"
              :loading="addressesAreLoading"
              size="xs"
            />
            <span
              v-else
              class="shrink-0 mb-1 text-sm md:mb-0 md:text-base"
            >
              Despachos desde {{ addresses?.[0]?.communeName }}, {{ addresses?.[0]?.regionName }}
            </span>
            <span class="hidden md:inline-block">·</span>
            <template v-if="sessionStore.user?.id">
              <lokal-loading
                v-if="destinationAddressLoading"
                :loading="destinationAddressLoading"
                size="xs"
              />
              <maker-shipping-price
                v-else-if="destinationAddress?.regionName && destinationAddress?.communeName"
                :maker-id="maker.id"
                :destination-address="destinationAddress"
                :carrier-codes="carrierCodes"
                :in-checkout="false"
              >
                <template #shipping-message="{ validMakerDestination }">
                  <span v-if="validMakerDestination">Costo estimado de despacho:</span>
                  <span v-else>Esta marca no despacho a tu dirección actual.</span>
                </template>
              </maker-shipping-price>
            </template>
          </p>
          <div class="flex items-center mb-3 w-auto text-sm text-gray-700 md:text-base">
            <div
              :class="{ 'w-auto': showCompleteDescription, 'truncate': !showCompleteDescription }"
              v-html="DOMPurify.sanitize(truncatedDescription, { USE_PROFILES: { html: true }})"
            />
            <button
              class="flex shrink-0 items-center md:ml-3"
              :class="{ 'self-center': !showCompleteDescription, 'self-start': showCompleteDescription }"
              @click="showCompleteDescription = !showCompleteDescription"
            >
              <span class="hidden mr-2 underline md:inline-block">{{ showCompleteDescription ? 'Ver menos' : 'Ver más' }}</span>
              <ChevronDownIcon
                class="shrink-0 w-5 h-5"
                :class="{ 'rotate-180': showCompleteDescription }"
              />
            </button>
          </div>
          <p class="mb-3 text-sm md:text-base">
            Tiempo promedio de despacho es de
            <span class="font-semibold">{{ maker.averageDeliveryDays }}</span>
            días hábiles desde la fecha de confirmación.
          </p>
        </div>
        <div class="flex flex-col mb-3 w-full md:flex-row md:items-center">
          <button
            v-if="maker.samplesEnabled"
            class="p-1.5 mr-0 mb-1 text-sm text-left hover:text-white hover:bg-gray-700 rounded-md border border-gray-700 md:p-2 md:mr-3 md:mb-0 md:text-base"
            @click="showSampleRequestDialog = !showSampleRequestDialog"
          >
            ¿Te gustaría solicitar una muestra?
          </button>
          <button
            class="p-1.5 text-sm text-left hover:text-white hover:bg-gray-700 rounded-md border border-gray-700 md:p-2 md:text-base"
            @click="showMissingProductsRequestDialog = !showMissingProductsRequestDialog"
          >
            ¿Faltan productos?
          </button>
        </div>
        <div class="flex overflow-x-auto relative flex-col gap-2 pb-5 mb-4 w-full snap-x snap-proximity sm:flex-row sm:mb-6 md:gap-3">
          <maker-show-shippings
            class="shrink-0 w-full snap-center sm:w-1/2"
            :maker="maker"
          />
          <maker-show-discounts
            :maker="maker"
            class="shrink-0 w-full snap-center sm:w-1/2 lg:w-2/5"
          />
        </div>
        <product-list-display
          :maker-slug="maker.slug"
          :maker-query-param="`q[maker_id_eq]=${maker.id}&q[s][]=status+desc&q[s][]=maker_priority+asc&`"
          :maker="maker"
          :total-product-count="totalProductCount"
        />
      </div>
    </div>
    <maker-show-missing-products-request-dialog
      v-if="showMissingProductsRequestDialog"
      v-model="showMissingProductsRequestDialog"
      :maker="maker"
    />
    <maker-show-sample-request-dialog
      v-if="showSampleRequestDialog"
      v-model="showSampleRequestDialog"
      :maker="maker"
    />
    <maker-show-send-message-dialog
      v-if="showSendMessageDialog"
      v-model="showSendMessageDialog"
      :maker="maker"
    />
    <lokal-user-register-dialog
      v-if="showRegisterDialog"
      v-model="showRegisterDialog"
      :initial-value-sign-in="false"
    />
    <div
      v-show="showFreeShippingBanner"
      class="flex fixed bottom-0 z-20 p-2 w-full text-amber-900 bg-yellow-50 md:p-3"
    >
      <bottom-message-list-card
        :maker-id="maker.id"
        class="w-full"
        @maker-enabled="(value: boolean) => showFreeShippingBanner = value"
      />
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, computed, inject } from 'vue';
import DOMPurify from 'isomorphic-dompurify';
import { ChatBubbleLeftRightIcon, ChevronDownIcon } from '@heroicons/vue/24/solid';
import useSessionStore from 'stores/user-store';
import useGeneralMenuStore from 'stores/general-menu-store';
import greenKImage from 'assets/images/green-k.jpg';
import ProductListDisplay from './product-list-display.vue';
import useFormatCurrency from './use/format-currency';
import type { ColorKeyType } from './shared/lokal-banner.vue';
import makerShowDiscounts from './maker-show-discounts.vue';
import makerShowSendMessageDialog from './maker-show-send-message-dialog.vue';
import makerShowShippings from './maker-show-shippings.vue';
import lokalUserRegisterDialog from './shared/lokal-user-register-dialog.vue';
import MakerShowSampleRequestDialog from './maker-show-sample-request-dialog.vue';
import MakerShowMissingProductsRequestDialog from './maker-show-missing-products-request-dialog.vue';
import MakerShippingPrice from './shared/maker-shipping-price.vue';
import useCarriersQuery from './queries/carriers-query';
import useAddressesQuery from './queries/addresses-query';
import useShopQuery from './queries/shop-query';
import useCommunesQuery from './queries/communes-query';
import useRegionsQuery from './queries/regions-query';
import productListAsideFilters from './product-list-aside-filters.vue';
import bottomMessageListCard from './bottom-message-list-card.vue';

const MD_CHARACTERS_TO_SHOW = 100;
const CHARACTERS_TO_SHOW = 50;

interface MakerShowProps {
  maker: Maker,
  totalProductCount: number,
  messages?: string[][]
}

const props = withDefaults(defineProps<MakerShowProps>(), {
  messages: () => [['', '']],
});

const menuStore = useGeneralMenuStore();
const isMobile = inject('isMobile');
const sessionStore = useSessionStore();

const showFreeShippingBanner = ref(false);
const showRegisterDialog = ref(false);
const showSendMessageDialog = ref(false);
const showSampleRequestDialog = ref(false);
const showMissingProductsRequestDialog = ref(false);
function manageShowSendMessageDialog() {
  if (sessionStore.user?.id) {
    showSendMessageDialog.value = true;
  } else {
    showRegisterDialog.value = true;
  }
}

const showCompleteDescription = ref(false);
const charactersToShow = computed(() => (isMobile.value ? CHARACTERS_TO_SHOW : MD_CHARACTERS_TO_SHOW));
const truncatedDescription = computed(() => {
  if (props.maker.description.length > charactersToShow.value && !showCompleteDescription.value) {
    return `${props.maker.description.slice(0, charactersToShow.value)} ...`;
  }

  return props.maker.description;
});
const coverImageUrl = computed(() =>
  props.maker.coverImage?.derivatives?.jpgXl?.url || greenKImage);

const showBanners = ref({} as { [string: number]: boolean });
props.messages.forEach((_, index) => {
  showBanners.value[index] = true;
});

const { addresses, addressesQuery } = useAddressesQuery(computed(() => props.maker.id), computed(() => 'Maker'));
const addressesAreLoading = computed(() => addressesQuery.isLoading.value);

const carriersQueryParams = computed(() =>
  `q[maker_carriers_maker_id_eq]=${props.maker.id}&q[maker_carriers_active_eq]=true`);
const { carriers, carriersQuery } = useCarriersQuery(carriersQueryParams);
const carrierCodes = computed(() => carriers.value?.map((carrier) => carrier.code));

const currentAddress = computed(() => sessionStore.currentAddress);

const shopQueryEnabled = computed(() => !!sessionStore.user?.shopId && !currentAddress.value?.id);
const { shop, shopQuery } = useShopQuery(computed(() => sessionStore.user?.shopId), shopQueryEnabled);
const { communes, communesQuery } = useCommunesQuery(computed(() => ''), shopQueryEnabled);
const { regions, regionsQuery } = useRegionsQuery(shopQueryEnabled);
const destinationAddressLoading = computed(() =>
  carriersQuery.isLoading.value || shopQuery.isLoading.value || communesQuery.isLoading.value ||
  regionsQuery.isLoading.value);

function constructPartialAddress(commune: Commune, region: Region) {
  if (!commune || !region) return {} as Address;

  return {
    addressableType: 'Shop',
    addressableId: shop.value?.id,
    communeId: commune.id,
    regionId: region.id,
    communeName: commune.name,
    regionName: region.name,
  };
}

const destinationAddress = computed(() => {
  if (currentAddress.value?.id) return currentAddress.value;
  if (shop.value?.referenceCommuneId && shop.value?.referenceRegionId) {
    const internalCommune = communes.value?.find((commune) => commune.id === shop.value?.referenceCommuneId);
    const internalRegion = regions.value?.find((region) => region.id === shop.value?.referenceRegionId);

    return constructPartialAddress(internalCommune as Commune, internalRegion as Region);
  }

  return {} as Address;
});
</script>

