import ahoy from 'ahoy.js';
import { camelizeKeys } from 'humps';
import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import { createPinia } from 'pinia';
import useScreenSizes from 'components/use/screen-sizes';
import InlineSvg from 'vue-inline-svg';
import FloatingVue from 'floating-vue';
import { VueQueryPlugin } from 'vue-query';
import 'floating-vue/dist/style.css';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import App from '../components/app.vue';
import '../css/application.css';
import messages from '../locales';
import useVeeValidateConfiguration from '../components/use/vee-validate-configuration';
import useBillTools from '../components/use/bill-tools';

import TheHeader from '../components/the-header.vue';
import TheFooter from '../components/the-footer.vue';
import TheLanding from '../components/the-landing.vue';
import ReturnPolicy from '../components/the-landing-return-policy.vue';
import ShippingPolicy from '../components/the-landing-shipping-policy.vue';
import TermsOfService from '../components/the-landing-terms-of-service.vue';
import AboutUs from '../components/the-landing-about-us.vue';
import PotentialMakerContact from '../components/potential-maker-contact.vue';
import ProductList from '../components/product-list.vue';
import ProductShow from '../components/product-show.vue';
import SignSelector from '../components/sign-selector.vue';
import PasswordEdit from '../components/password-edit.vue';
import PasswordNew from '../components/password-new.vue';
import ShopNew from '../components/shop-new.vue';
import ShopShow from '../components/shop-show.vue';
import ShopAccount from '../components/shop-account.vue';
import MakerList from '../components/maker-list.vue';
import MakerShow from '../components/maker-show.vue';
import MakerAccount from '../components/maker-account.vue';
import SubOrderList from '../components/sub-order-list.vue';
import SubOrderShow from '../components/sub-order-show.vue';
import MakersProductList from '../components/makers-product-list.vue';
import OrderNew from '../components/order-new.vue';
import OrderShow from '../components/order-show.vue';
import OrderList from '../components/order-list.vue';
import ConfirmationNew from '../components/confirmation-new.vue';
import LokalButton from '../components/shared/lokal-button.vue';
import LokalBanner from '../components/shared/lokal-banner.vue';
import LokalBannerMessageDialog from '../components/shared/lokal-banner-message-dialog.vue';
import WhatsappFloatingContact from '../components/shared/whatsapp-floating-contact.vue';
import MakerRefer from '../components/maker-refer.vue';
import LokalLoading from '../components/shared/lokal-loading.vue';
import OrderNewPayment from '../components/order-new-payment.vue';
import MakersProductEdit from '../components/makers-product-edit.vue';
import MakersProductEditWrapper from '../components/makers-product-edit-wrapper.vue';
import TipTapEditor from '../components/shared/tip-tap-editor.vue';
import CategoryList from '../components/category-list.vue';
import TutorialList from '../components/tutorial-list.vue';
import TheSidebar from '../components/the-sidebar.vue';
import BottomMessages from '../components/bottom-messages.vue';
import FrequentQuestions from '../components/frequent-questions.vue';
import MakerReferredEmbed from '../components/maker-referred-embed.vue';
import MakerShopShow from '../components/maker-shop-show.vue';
import MakerReferredUserShow from '../components/maker-referred-user-show.vue';
import CartSidebar from '../components/cart-sidebar.vue';
import OrderCheckout from '../components/order-checkout.vue';
import ShippingQuotations from '../components/shipping-quotations.vue';
import MakerShippingQuotationList from '../components/maker-shipping-quotation-list.vue';
import TimedMessage from '../components/shared/timed-message.vue';
import TheEnviroment from '../components/the-enviroment.vue';

// eslint-disable-next-line max-statements
document.addEventListener('DOMContentLoaded', () => {
  ahoy.configure({ withCredentials: true });
  ahoy.trackView();

  const i18n = createI18n({
    legacy: false,
    locale: 'es',
    fallbackLocale: 'es',
    messages,
  });

  const app = createApp({
    components: {
      App, TheHeader, ProductList, ProductShow, SignSelector, ShopNew, ShopShow, MakerList, MakerShow,
      SubOrderList, MakersProductList, SubOrderShow, OrderNew, OrderShow, OrderList, TheFooter, TheLanding,
      ReturnPolicy, ShippingPolicy, TermsOfService, PasswordEdit, PasswordNew, ConfirmationNew,
      WhatsappFloatingContact, LokalBanner, MakerRefer, OrderNewPayment, MakersProductEdit, CategoryList,
      TutorialList, ShopAccount, MakerAccount, TheSidebar, FrequentQuestions, AboutUs, MakerReferredEmbed,
      PotentialMakerContact, MakerShopShow, MakerReferredUserShow, CartSidebar, OrderCheckout, ShippingQuotations,
      MakerShippingQuotationList, TimedMessage, MakersProductEditWrapper, TheEnviroment, BottomMessages,
    },
    // eslint-disable-next-line max-statements
    setup() {
      useVeeValidateConfiguration();
      useScreenSizes();
      useBillTools();
    },
    methods: {
      camelizeKeys,
    },
  });

  const pinia = createPinia();

  window.onpageshow = function (event) {
    if (event.persisted) {
      window.location.reload();
    }
  };

  app.component('VueDatePicker', VueDatePicker);
  app.component('TipTapEditor', TipTapEditor);
  app.component('LokalButton', LokalButton);
  app.component('LokalLoading', LokalLoading);
  app.component('LokalBannerMessageDialog', LokalBannerMessageDialog);
  app.component('InlineSvg', InlineSvg);
  app.use(pinia);
  app.use(i18n);
  app.use(FloatingVue);
  app.use(VueQueryPlugin);
  app.mount('#vue-app');

  return app;
});

